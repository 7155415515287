<template>
    <!-- 威有动静 -->
    <div class="movement">
        <DataList></DataList>
    </div>
</template>

<script>
import DataList from '../components/content/Movement/DataList.vue';

export default {
    name: 'Movement',
    components: {
        DataList,
    },
};
</script>

<style lang="less" scoped>
.movement {
    margin: 0 auto;
}
</style>
